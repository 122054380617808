import React from 'react'
import Layout from '../../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeOne from "../../components/layout/sidemenu/sidemenu_se_one"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit1Icon from '../../images/icons/unit_01_icon.svg'
import waterMoleculeA33 from '../../images/student-pages/unit-1/33_water_molecules.gif'
import globalIceVolume38 from '../../images/student-pages/unit-1/38_global_ice_volume.gif'


const GlobalConnections14 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 1.4 Global Connections" />
    <Container className="mb-4" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeOne location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'1.4 Global Connections'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit1Icon }
                iconAlt="Unit 1 icon"
                studentPageHeader="Unit 1: Carbon &amp; Climate in the Past"
                unitNumber="1.4"
                studentPageHeaderSub="Global Connections"
                sectionA={ <>Evaporation Separation</> }
                sectionALink="/unit-1/1.4-global-connections/#evaporation-separation"
                sectionB={ <>Heavy Water</> }
                sectionBLink="/unit-1/1.4-global-connections/#heavy-water"
                sectionC={ <>Ice Age Proxies</> }
                sectionCLink="/unit-1/1.4-global-connections/#ice-age-proxies"
                sectionD={ <>Reflect and Connect</> }
                sectionDLink="/unit-1/1.4-global-connections/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>How can we tell whether climate signals in Earth's past are global or regional in nature?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image31.childImageSharp.fluid}
                          alt="Polar cap"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageLeft rounded mr-3 img-fluid d-none d-md-block"
                      fluid={props.data.image31.childImageSharp.fluid}
                      alt="Polar cap"
                    />

                    <p>In Lesson 1.3, you studied how forcings cause changes, or responses. One forcing could cause several responses. You also observed how climate scientists, like those at NASA, use climate models to test forcings and responses for Earth's climate. They test the accuracy of such models by changing the level of greenhouse gases in the model atmosphere. For example, they can adjust CO<sub>2</sub> levels to match those observed from the Antarctica ice core record. This lets them test how the models match climate variations observed for hundreds of thousands of years. Data from the models and the ice cores match very well.</p>
                  </>
                }

                paragraphTwo={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image32.childImageSharp.fluid}
                          alt="Antarctica ice sheets"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="verticalImageRight rounded ml-3 mb-1 img-fluid d-none d-md-block"
                      fluid={props.data.image32.childImageSharp.fluid}
                      alt="Antarctica ice sheets"
                    />

                    <p>
                      But did those variations of CO<sub>2</sub> and climate occur only in Antarctica? If you read <Link to="/unit-1/1.2-carbon-from-the-past/#explore-more-carbon-cycle-connections"><em>Explore More: Carbon Cycle Connections</em></Link> in Lesson 1.2, you saw some maps that showed large changes in Earth's ecosystems between glacial and interglacial periods. In this lesson, you'll analyze other evidence of past climate that you can compare with the Antarctic data. In particular, you will:
                    </p>

                    <ul>
                      <li>Investigate whether climate signals are global or regional.</li>
                      <li>Analyze other indicators of climate cycles on Earth.</li>
                      <li>Use models of molecules to simulate physical processes on Earth.</li>
                    </ul>

                    <p>
                      Think about the focus question as you go through this lesson. It will also help you prepare for activities in Lesson 1.5.
                    </p>
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="evaporation-separation" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">Evaporation Separation</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3 imageBorder"
                            src={ waterMoleculeA33 }
                            alt="Water molecule A"
                          />
                        </Col>
                      </Row>
                      <img
                        className="verticalImageRight imageBorder rounded ml-3 img-fluid d-none d-md-block"
                        src={ waterMoleculeA33 }
                        alt="Water molecule A"
                      />

                      <p>
                        The <strong>water cycle</strong> is a key part of understanding Earth's climate. One process of the water cycle is evaporation. <strong>Evaporation</strong> is where molecules of H<sub>2</sub>O leave the liquid state to be molecules of H<sub>2</sub>O vapor in the air. As a vapor, H<sub>2</sub>O can then move through the atmosphere. As water moves around Earth, it contains a subtle fingerprint of climate. This is because not all water molecules are the same: Some H<sub>2</sub>O molecules have more mass than other H<sub>2</sub>O molecules.
                      </p>

                      <p>
                        Look at the Water Molecules A and B diagrams. Both of these have one oxygen atom and two hydrogen atoms, but they are not the same:
                      </p>

                      <ul>
                        <li>Molecule A has an oxygen atom with a mass of 16. This comes from 8 protons and 8 neutrons. Each of the two hydrogen atoms has a mass of one. The total mass of Molecule A (H<sub>2</sub><sup>16</sup>O) is 16+2, or 18.</li>
                        <li>Molecule B has an oxygen atom with a mass of 18. This comes from 8 protons and 10 neutrons. Adding the mass of the two hydrogen atoms gives a total mass for Molecule B (H<sub>2</sub><sup>18</sup>O) of 20.</li>
                      </ul>

                      <p>
                        These molecules have different masses because <sup>16</sup>O and <sup>18</sup>O are two isotopes of oxygen. Two atoms are <strong>isotopes</strong> if they have the same number of protons, but different numbers of neutrons. This gives them slightly heavier or lighter masses.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="" className="cardHeader">
                      <span className="headerFontBlue"><h2>Evaporation Table</h2></span>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        But how does this relate to processes on Earth? Complete the steps below to model evaporation for H<sub>2</sub>O molecules with different masses. You may do this as a class, but be sure to record your own answers in your science notebook. Later in the lesson, you will connect this model with patterns of climate on Earth.
                      </p>

                      <ol>
                        <li>
                          Look at the particles that your teacher has in a box.
                          <ol type="a">
                            <li>How are the particles similar or different? </li>
                            <li>What do the particles represent?</li>
                            <li>What do you think is represented by the entire box of particles?</li>
                          </ol>
                        </li>
                        <li>Imagine that some of the particles were to evaporate and leave the box. With a partner, predict which type of particles would leave the box. Would it be only the heavy or only the light? Be ready to discuss your prediction and reasoning with your class. You will then get to test your prediction.</li>
                        <li>Copy headings from the Evaporation table to your science notebook. Count each kind of particle, and enter the totals in the first row. Now, figure out the ratio of heavy-to-light particles (H/L).</li>
                      </ol>

                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col"></th>
                            <th className="table-active" scope="col">Total</th>
                            <th className="table-active" scope="col">Number of Heavy (H)</th>
                            <th className="table-active" scope="col">Number of Light (L)</th>
                            <th className="table-active" scope="col">Ratio of Heavy to Light (H/L) in Box</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Initial</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>In box after shake #1</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>In box after shake #2</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>In box after shake #3</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image34.childImageSharp.fluid}
                            alt="Shake experiment"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight imageBorder rounded ml-2 img-fluid d-none d-md-block"
                        fluid={props.data.image34.childImageSharp.fluid}
                        alt="Shake experiment"
                      />

                      <ol start="4">
                        <li>
                          To model evaporation, a volunteer will shake the box vigorously. Some of the balls will fly out of the box. About four or five volunteers will hold a sheet beneath the box to catch the particles. This is <strong>shake #1</strong>. Do this for about 10 seconds, or until about one quarter of the particles have bounced out of the box.
                          <ol type="a">
                            <li>For the particles that left the box, what do you think they represent?</li>
                            <li>For the particles still in the box, what do they represent?</li>
                            <li>Count the particles left in the box. Record in your table for the groups of total, heavy (H), and light (L).</li>
                            <li>Determine the ratio of heavy-to-light particles. You may write this as (H/L) or (H:L). How does this compare with the initial ratio?</li>
                          </ol>
                        </li>
                        <li>
                          Think about the particles that flew out of the box. <em>Do not put them back in the box</em>.
                          <ol type="a">
                            <li>If these particles were returned to the box, what would be (H/L) ratio in the box?</li>
                            <li>What part of the Earth system could represent the particles returning to the box?</li>
                            <li>Now, store them in another container away from the box. What is a region on Earth or process where this could happen?</li>
                            <li>What climate conditions would favor the particles being stored somewhere else?</li>
                          </ol>
                        </li>
                        <li>Repeat step 4 above. This is <strong>shake #2</strong>. Repeat again for <strong>shake #3</strong>.</li>
                        <li>
                          After recording the results for shake #3, answer these questions.
                          <ol type="a">
                            <li>How does the initial ratio compare with the ratio after shake #3?</li>
                            <li>Return all of the stored particles to the original bin. How does the (H/L) in the box change?</li>
                            <li>Overall, what kind of climate conditions do you think would favor particles returning to the box?</li>
                          </ol>
                        </li>
                        <li>A Features chart can help you connect features of the model with processes or features on Earth. Copy and fill out the table below to help you make those connections. Be sure to add your reasons for the connections in the "because" column.</li>
                      </ol>

                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col">Feature of model with foam balls</th>
                            <th className="table-active" scope="col">is like</th>
                            <th className="table-active" scope="col">Feature of Earth system with H<sub>2</sub>O</th>
                            <th className="table-active" scope="col">because&hellip;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Balls without tacks</td>
                            <td></td>
                            <td>&hellip;</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Balls with tacks</td>
                            <td></td>
                            <td>H<sub>2</sub><sup>18</sup>O</td>
                            <td>&hellip;when shaking the bin and adding energy, water with the heavy oxygen isotope (<sup>18</sup>O) is less likely to evaporate and stays in the bin, compared with H<sub>2</sub><sup>16</sup>O</td>
                          </tr>
                          <tr>
                            <td>&hellip;</td>
                            <td></td>
                            <td>ice sheets</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Large bin with balls</td>
                            <td></td>
                            <td>&hellip;</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Sheet gathering flying balls</td>
                            <td></td>
                            <td>&hellip;</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Energy of shaking</td>
                            <td></td>
                            <td>&hellip;</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>&hellip;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="heavy-water" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Heavy Water</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        In the last activity, you used a model of the Earth system and the water cycle. The water molecules H<sub>2</sub><sup>16</sup> and H<sub>2</sub><sup>18</sup>O behave differently in the water cycle. The lighter H<sub>2</sub><sup>16</sup> evaporates more readily from the ocean than the heavier H<sub>2</sub><sup>18</sup>. In the evaporation/shake model, the lighter balls bounced out of the box more easily. The model can also be used to see how changes in the water cycle help geoscientists understand past climate patterns on Earth.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image35.childImageSharp.fluid}
                            alt="Heavy water map"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight rounded ml-2 img-fluid d-none d-md-block"
                        fluid={props.data.image35.childImageSharp.fluid}
                        alt="Heavy water map"
                      />

                      <p>
                        During ice ages (glacial periods, or "glacials"), the amount of ice on Earth was much greater than today. In addition to Antarctica and Greenland, ice sheets covered much of the United States, Canada, and Europe. You saw this in <Link to="/unit-1/1.2-carbon-from-the-past/">Lesson 1.2</Link>. Water that evaporated from the ocean moved over land, and was added to the ice sheets as snowfall. On average, this snow tends to be the low-mass molecule of water, the H<sub>2</sub><sup>16</sup>O. When snow falls on the ice sheet, it stays there for a very long time. Thus, the ice sheet stores relatively more H<sub>2</sub><sup>16</sup>O than H<sub>2</sub><sup>18</sup>O. Conversely, the amount of H<sub>2</sub><sup>18</sup>O in the ocean increases compared to the amount of H<sub>2</sub><sup>16</sup>O. When water is stored in ice sheets on continents, oceans have more H<sub>2</sub><sup>18</sup>O.
                      </p>

                      <p>
                        In Lesson 1.1, you used an indicator for carbon and CO<sub>2</sub> in water. Geoscientists also use an indicator for the amount of H<sub>2</sub><sup>18</sup>O in ocean water: &delta;<sup>18</sup>O. It's pronounced "delta-18-O." The delta stands for "change," and the <sup>18</sup>O represents the <sup>18</sup>O in H<sub>2</sub><sup>18</sup>O. As with the shaking model, evaporation tends to remove H<sub>2</sub><sup>16</sup>O from oceans and store it in ice sheets. As the H<sub>2</sub><sup>18</sup>O in oceans increases, the value of &delta;<sup>18</sup>O in the ocean also goes up. Ice sheets form in cold climates or during glacial periods. So, glacial periods connect with times of high &delta;<sup>18</sup>O in the ocean.
                      </p>

                      <p>
                        In the past, continental ice sheets were more than 2 kilometers thick in areas of the United States, Canada, and northern Europe and Asia. But these areas are not covered by ice sheets now. Where did the ice sheets go? Think back to where you returned the balls that had shaken out ("evaporated") to the box. You know a process in the water cycle that would return the H<sub>2</sub>O from ice sheets to the ocean. When ice sheets melt, the H<sub>2</sub><sup>16</sup>O returns by rivers to the oceans.
                      </p>

                      <ol start="9">
                        <li>Add to the table below to summarize the changes in &delta;<sup>18</sup>O in the ocean that occur as climate gets warmer or colder.</li>
                      </ol>

                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col">Climate</th>
                            <th className="table-active" scope="col">	Net Movement of H<sub>2</sub>O in water cycle</th>
                            <th className="table-active" scope="col">&delta;<sup>18</sup>O of oceans (&uarr; or &darr;)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Cooling to glacial period</td>
                            <td>Warming to interglacial period</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>oceans to ice sheets</td>
                            <td>ice sheets to oceans</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="ice-age-proxies" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">Ice Age Proxies</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        How do geoscientists measure &delta;<sup>18</sup>O of ocean water in the past? How do they retrieve evidence of past climate? They get the data from foraminifera, or forams for short. <strong>Foraminifera</strong> are single-celled organisms that live in the ocean and are a key part of the marine food web. They are widespread in the oceans. Depending on the type, they live in surface waters, or all the way down to the ocean floor. See the example photo of a live foram. When forams die, they fall toward the sea floor and become part of ocean sediments.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image36.childImageSharp.fluid}
                            alt="Ice age proxies"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageLeft rounded mr-3 img-fluid d-none d-md-block"
                        fluid={props.data.image36.childImageSharp.fluid}
                        alt="Ice age proxies"
                      />

                      <p>
                       A portion of the forams is made up of calcium carbonate (CaCO<sub>3</sub>). This part of a foram is called a <strong>test</strong>, and they are very tiny, only about 0.1-1.0 millimeters. Tests are like the shell of a clam, but are on the inside of the organism (that is, the endoskeleton). For the live foram to the left, its test is shown below. After death, this is what forams look like when their organic parts have decayed. These tests can reveal key climate signals.
                      </p>

                      <p>
                        The oxygen in the CaCO<sub>3</sub> that makes up the tests comes from both the H<sub>2</sub><sup>16</sup>O and H<sub>2</sub><sup>18</sup>O in the oceans. The &delta;<sup>18</sup>O of the oxygen in the CaCO<sub>3</sub> tells us about Earth's climate during the time when the foram was alive. The &delta;<sup>18</sup>O of the tests of forams are a proxy record that tells us about Earth's past climate. <strong>Proxy records</strong> are data that stand for, or represent, a related feature. Another example of a proxy record is tree rings: The width of tree rings can tell us about yearly rainfall.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image37.childImageSharp.fluid}
                            alt="Ice age proxies"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight rounded ml-2 img-fluid d-none d-md-block"
                        fluid={props.data.image37.childImageSharp.fluid}
                        alt="Ice age proxies"
                      />

                      <p>
                        Over thousands of years, foram tests gather with layers of other sediments on the sea floor. This is like the layers of ice you studied. In the ice record, key data is in the ice and trapped air bubbles. From the ocean record, key data is in both the sediments and foram tests in the sediments. When Earth's climate was overall cold, ice sheets grew with water evaporated from the ocean. You saw that the evaporated water had relatively more H<sub>2</sub><sup>16</sup>O than the ocean. At the same time, the ocean water was left with relatively more H<sub>2</sub><sup>18</sup>O than H<sub>2</sub><sup>16</sup>O. This resulted in higher &delta;<sup>18</sup>O values for water and foram carbonate.
                      </p>

                      <p>
                        When Earth's climate warmed, ice sheets began to melt. The water returning to the ocean had relatively higher levels of H<sub>2</sub><sup>16</sup>O. This resulted in &delta;<sup>18</sup>O values for both the ocean and the foram carbonate to decrease. When lots of foram data are combined, their &delta;<sup>18</sup>O values give a proxy record for <strong>global ice volume</strong>. This pattern is shown in the Global Ice Volume graph. The cycles show changes in ice total ice volume between glacial and interglacial periods. After seeing this graph, you can view an animation again of glacial-interglacial cycles from <Link to="/unit-1/1.2-carbon-from-the-past/">Lesson 1.2</Link>.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ globalIceVolume38 }
                            alt="Global ice volume (proxy record)"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter rounded mb-4 img-fluid d-none d-md-block"
                        src={ globalIceVolume38 }
                        alt="Global ice volume (proxy record)"
                      />

                      <ol start="10">
                        <li>
                          Look at the axes on the Global Ice Volume graph. You will receive a handout of the graph. Write your answers on the graph.
                          <ol type="a">
                            <li>What is the x-axis? What does it stand for?</li>
                            <li>What is the y-axis?</li>
                            <li>The value at 440,000 years has a high value of &delta;<sup>18</sup>O. Is this a glacial or interglacial period?</li>
                          </ol>
                        </li>
                        <li>How does the value at 440,000 years compare with the value at 21,000 years?</li>
                        <li>The &delta;<sup>18</sup>O for current times and 125,000 years ago have low values. Are these glacial or interglacial periods?</li>
                        <li>Does Earth show evidence for one big ice age? How many glacial periods, or ice ages, do you count in the last 650,000 years?</li>
                        <li>
                          Another proxy for global climate is <strong>sea level</strong>. This is changes when water is locked in glaciers or is returned to the sea. Complete the two sentences below. They state how &delta;<sup>18</sup>O is related to sea level. Enter "high" or "low" for values of &delta;<sup>18</sup>O or sea level. <br />
                          <em>When the Earth's climate was in a glacial period, the sea level was relatively &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;. This is values of &delta;<sup>18</sup>O that are &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;</em> <br />
                          <em>When the Earth's climate was in an interglacial period, the sea level was relatively &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;. This is values of &delta;<sup>18</sup>O that are &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em>
                        </li>
                      </ol>

                      <p>
                        Watch the following video to learn more about forams.
                      </p>

                      <div className="embed-container">
                        <iframe title="foramstale" src="https://player.vimeo.com/video/307777331" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        D. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        You can bring together some ideas from Unit 1 with the following questions. They will help you summarize past changes in climate on Earth.
                      </p>

                      <ol start="12">
                        <li>
                          Complete the Ice Sheet table to summarize a number of climate effects that you have been discussing. The effects are in the top row. For the first column with ice sheet size, fill in the related climate effects to the right. <br />
                          <em>Hint: Some relationships are shown in the Climate Records interactive.</em>
                        </li>
                      </ol>

                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col">Ice sheet size</th>
                            <th className="table-active" scope="col">CO<sub>2</sub> level in atmosphere</th>
                            <th className="table-active" scope="col">Mean global climate</th>
                            <th className="table-active" scope="col">	&delta;<sup>18</sup>O of oceans</th>
                            <th className="table-active" scope="col">sea level</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>massive</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>low</td>
                          </tr>
                          <tr>
                            <td>small</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>zero</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>medium</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>

                      <ol start="13">
                        <li>Get the Climate Indicators handout from your teacher. Use it to summarize how several factors in the Ice Sheet table connect to the ice volume curve. Add the following phrases to one of the boxes.</li>
                      </ol>

                      <table className="table table-hover table-responsive-md studentTable">
                        <tbody>
                          <tr>
                            <td>High CO<sub>2</sub></td>
                            <td>high sea level</td>
                            <td>warm global temperature</td>
                            <td>large ice sheets</td>
                            <td>higher &delta;<sup>18</sup>O</td>
                          </tr>
                          <tr>
                            <td>Low CO<sub>2</sub></td>
                            <td>low sea level</td>
                            <td>cool global temperature</td>
                            <td>small ice sheets</td>
                            <td>lower &delta;<sup>18</sup>O</td>
                          </tr>
                        </tbody>
                      </table>

                      <ol start="14">
                        <li>Recall the focus question for this lesson. Do you see climate as being global or regional in nature?</li>
                        <li>
                          You have been talking about climate in these lessons. You may not have realized it, but the topics also connect closely with the carbon cycle. Consider the two substances below with carbon. Describe how you think they may be part of the carbon cycle.
                          <ol type="a">
                            <li>Carbon dioxide (CO<sub>2</sub>)</li>
                            <li>Calcium carbonate (CaCO<sub>3</sub>)</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <ButtonNext
                ButtonText="1.5: Core Connections"
                NextLink="/unit-1/1.5-core-connections/"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default GlobalConnections14

export const query = graphql`
  query {
    image31: file(relativePath: { eq: "student-pages/unit-1/31_polar_cap.jpg" }) {
      ...rowImage
    }
    image32: file(relativePath: { eq: "student-pages/unit-1/32_ice_antartica.jpg" }) {
      ...rowImage
    }
    image34: file(relativePath: { eq: "student-pages/unit-1/34_shake_experiment.jpg" }) {
      ...rowImage
    }
    image35: file(relativePath: { eq: "student-pages/unit-1/35_heavy_water.jpg" }) {
      ...rowImage
    }
    image36: file(relativePath: { eq: "student-pages/unit-1/36_ice_age_proxies_1.png" }) {
      ...rowImage
    }
    image37: file(relativePath: { eq: "student-pages/unit-1/37_ice_age_proxies_2.jpg" }) {
      ...rowImage
    }
  }
`
